<template>
    <!-- TODO: Use BaseImage when real avatar image data is available -->
    <div class="base-avatar">
        <img :src="avatarImage" alt="">
    </div>
</template>

<script setup>
const props = defineProps({
    image: {
        type: String,
        default: null
    },
    useUserAvatar: {
        type: Boolean,
        default: false
    },
    avatarFallback: {
        type: String,
        default: '/images/avatar-placeholder.png'
    }
});

const authStore = useAuthStore();

const avatarImage = computed(() => {
    if (props.useUserAvatar) {
        return authStore.user?.avatar?.conversions?.webp_256 || props.avatarFallback;
    }

    return props.image || props.avatarFallback;
});
</script>

<style lang="less">
.base-avatar {
    border-radius: var(--borderRadius-full);
    aspect-ratio: 1 / 1;
    min-height: 1rem;
    overflow: hidden;

    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.base-avatar--default {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #dce8e8;
    color: var(--color-secondary);
}
</style>
